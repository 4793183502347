<template>
  <div>
    <component :is="$route.meta.layout || 'div'" />
    <Toaster
      position="top-right"
      :theme="isDark ? 'dark' : 'light'"
      rich-colors
    />
    <ModalsContainer />
  </div>
</template>

<script setup>
import { useHead } from 'unhead'
import { onMounted } from 'vue'
import { ModalsContainer } from 'vue-final-modal'
import { toast, Toaster } from 'vue-sonner'

import useTheme from '@/core/theme.js'
import getTitle from '@/utils/get-title.js'
import isBrowser from '@/utils/is-browser.js'

const title = getTitle()
const isDark = useTheme()

useHead({
  titleTemplate: (pageTitle) =>
    !pageTitle ? title : `${pageTitle} | ${title}`,
})

onMounted(() => {
  if (isBrowser && import.meta.env.DEV) {
    if (window.VITE_ERROR) {
      toast.error(window.VITE_ERROR.title, {
        description: window.VITE_ERROR.description,
        duration: 5000,
      })
    }
  }
})
</script>
